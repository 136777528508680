import React from 'react';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import MeritHeader from '../../../components/about/MeritHeader';

export default () => (
  <Layout>
    <Seo title='¡Registrado!' keywords={[`success`, `application`, `react`]} />
    <MeritHeader>¡Gracias!</MeritHeader>
    <p>
      Has separado tu lugar en el taller Frontend Web Developer. Sin embargo,
      aún tienes que liquidar el pago. Tienes oportunidad hacerlo hasta el día
      del taller.
    </p>
    <h2>Cómo pagar</h2>
    <p>
      Puedes pagar en efectivo en el taller de electrónica "PICArt", ubicado en
      el campus Miravalle del Instituto Tecnológico de Saltillo. Te dejamos
      instrucciones de cómo llegar:
    </p>
    <div className='video-container'>
      <iframe
        width='100%'
        height='300'
        src='https://www.youtube.com/embed/W38upFAwsck'
      ></iframe>
    </div>
    <p>
      Si tienes alguna duda,{' '}
      <a href='http://m.me/leavetheordinary'>
        <b>
          <u>contáctanos</u>
        </b>
      </a>
      .
    </p>

    <style jsx>{`
      .video-container {
        max-width: 500px;
        margin-bottom: 30px;
      }
    `}</style>
  </Layout>
);
